var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Under Warranty Unit")]),_c('br'),_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.createUnderWarrantyUnit.apply(null, arguments)}}},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.unit.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUnits},model:{value:(_vm.unit.unit_id),callback:function ($$v) {_vm.$set(_vm.unit, "unit_id", $$v)},expression:"unit.unit_id"}}),(_vm.submitted && _vm.$v.unit.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.unit.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Hand Over Date*")]),_c('b-form-datepicker',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.unit.hand_over_date.$error,
                        },model:{value:(_vm.unit.hand_over_date),callback:function ($$v) {_vm.$set(_vm.unit, "hand_over_date", $$v)},expression:"unit.hand_over_date"}}),(_vm.submitted && _vm.$v.unit.hand_over_date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.unit.hand_over_date.required)?_c('span',[_vm._v("Date is required ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Expiry Date*")]),_c('b-form-datepicker',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.unit.expiry_date.$error,
                        },attrs:{"min":_vm.min},model:{value:(_vm.unit.expiry_date),callback:function ($$v) {_vm.$set(_vm.unit, "expiry_date", $$v)},expression:"unit.expiry_date"}}),(_vm.submitted && _vm.$v.unit.expiry_date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.unit.expiry_date.required)?_c('span',[_vm._v("Date is required ")]):_vm._e()]):_vm._e()],1)])]),_c('submitButton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }