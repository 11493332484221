<template>
    <Layout>
        <h4>New Under Warranty Unit</h4>
        <br />
        <form class="form-horizontal" @submit.prevent="createUnderWarrantyUnit">
            <div class="row mt-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="unit.unit_id"
                            :options="mappedUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.unit.unit_id.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.unit.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.unit.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="ownerfname"> Hand Over Date*</label>
                        <b-form-datepicker
                            v-model="unit.hand_over_date"
                            :class="{
                                'is-invalid':
                                    submitted && $v.unit.hand_over_date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.unit.hand_over_date.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.unit.hand_over_date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="ownerfname"> Expiry Date*</label>
                        <b-form-datepicker
                            v-model="unit.expiry_date"
                            :min="min"
                            :class="{
                                'is-invalid':
                                    submitted && $v.unit.expiry_date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.unit.expiry_date.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.unit.expiry_date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import { required } from "vuelidate/lib/validators";
import submitButton from "../../../components/submitButton.vue";
import axios from "axios";
import { BASE_URL } from "../../common";
import alertMixin from "../../../mixins/alertMixin";

export default {
    mixins: [alertMixin],
    validations: {
        unit: {
            unit_id: { required },
            hand_over_date: { required },
            expiry_date: { required },
        },
    },
    mounted() {
        this.$store.dispatch("apidata/fetchWarrantyUnits");
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);
        return {
            submitted: false,
            min: minDate,
            unit: {
                unit_id: "",
                hand_over_date: "",
                expiry_date: "",
            },
        };
    },
    components: {
        Layout,
        submitButton,
    },
    methods: {
        createUnderWarrantyUnit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                axios
                    .post(
                        `${BASE_URL}underwarrantyunits?token=${localStorage.cs_user_token}`,
                        this.unit
                    )
                    .then((res) => {
                        if (res.data.status_code === 200) {
                            this.successPopUp("Successfuly added a new unit");
                            this.resetFields();
                        }
                        loader.hide();
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        this.errorMsg("Failed to add unit");
                        loader.hide();
                    });
            }
        },
    },
    computed: {
        warrantyUnits() {
            return this.$store.getters["apidata/getUnitsForWarranty"];
        },
        mappedUnits() {
            return this.warrantyUnits.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.ref_no,
                };
            });
        },
    },
};
</script>
<style scoped></style>
